
import rm26App from "../images/rm26-app.svg";
import rm26Footer from "../images/rm26-footer.svg";

import instaSvg from "../images/insta.svg";
import linkedinSvg from "../images/linkedin.svg";
import facebookSvg from "../images/facebook.svg";
import twitterSvg from "../images/twitter.svg";
import appStorePng from "../images/app-store.png";
import googlePlayPng from "../images/google-play.png";
export default function Footer() {
    return (
        <footer>
            <div className="footer-top">
                <div className="img"><img src={rm26App} alt="Riyad Mahrez" /></div>
                <div className="linkApp">
                    <a href="#" className="app-store"><img src={appStorePng} alt="Riyad Mahrez" /></a>
                    <a href="#" className="google-play"><img src={googlePlayPng} alt="Riyad Mahrez" /></a>
                </div>
                <p>Conceived by the ingenuity of <strong><a href="https://mahrez-landing.dvpt.pulsweb.ch/" target="_blank">Riyad Mahrez</a></strong>, orchestrated by the efficiency of <strong><a href="https://www.katapultapp.net/" target="_blank">Katapult SA</a></strong>, and brought to life by the excellence of <strong><a href="https://pulse.digital/fr/" target="_blank">PULSE.digital</a></strong>.</p>
                <div className="social">
                    <a href="https://www.instagram.com/riyadmahrez26.7/" target="_blank" className="instagram"><img src={instaSvg} alt="Riyad Mahrez" /></a>
                    <a href="https://www.linkedin.com/in/riyad-mahrez-18863b154/" target="_blank" className="linkedin"><img src={linkedinSvg} alt="Riyad Mahrez" /></a>
                    <a href="https://www.facebook.com/Riyad.Mahrez.RM26.7" target="_blank" className="facebook"><img src={facebookSvg} alt="Riyad Mahrez" /></a>
                    <a href="https://twitter.com/Mahrez22" target="_blank" className="twitter"><img src={twitterSvg} alt="Riyad Mahrez" /></a>
                </div>
            </div>
            <div className="container">
                <div className="logo">
                    <a href="/"><img src={rm26Footer} alt="Riyad Mahrez" /></a>
                </div>
                <div className="link">
                    <a href="/terms_of_service">Terms of service</a> <a href="/privacy_policy">Privacy policy</a> <a href="/return_and_refund_privacy">Return and Refund policy</a>
                </div>
            </div>
        </footer>
    )
};