import NewsletterForm from '../components/NewsletterForm';
import appStorePng from "../images/app-store.png";
import googlePlayPng from "../images/google-play.png";
import riyadyPng from "../images/riyad.png";
import iphone15Png from "../images/iphone-15.png";
import videoCallPng from "../images/video-call.png";
import signedPng from "../images/signed.png";
import iphoneRm26Png from "../images/iphone-rm26.png";
import vipPng from "../images/vip.png";
import birthdayPng from "../images/birthday.png";

import SubscriptionItem from '../components/SubscriptionItem';

import ultraVipSvg from "../images/ultra-vip.svg";
import priceVipSvg from "../images/price.svg";
import okSvg from "../images/ok.svg";
import nokSvg from "../images/nok.svg";

import React from 'react';

import Header from '../components/hearder';
import Footer from '../components/footer';

//import { Button, Input } from "antd";

export default function Home() {


    return (
        <div className="App">

            <Header />
            <main>
                <div className="container content-top">
                    <div className="left">
                        <p>Application mobile RM26</p>
                        <h1><span>WelcomE to <br></br>the worLd of</span> <br></br>Riyad Mahrez</h1>
                        <div className="linkApp">
                            <a href="#" className="app-store"><img src={appStorePng} alt="Riyad Mahrez" /></a>
                            <a href="#" className="google-play"><img src={googlePlayPng} alt="Riyad Mahrez" /></a>
                        </div>
                    </div>
                    <div className="right">
                        <img src={riyadyPng} alt="Riyad Mahrez" />
                    </div>
                </div>
                <div className="content-about">
                    <div className="img">
                        <img src={iphone15Png} alt="Riyad Mahrez" />
                    </div>
                    <div className="right">
                        <h2>About Riyad <br></br>MAHREZ app</h2>
                        <p>Welcome to the era of Celebrity 2.0! <br></br><br></br>

                            Access exclusive content, behind-the-scenes videos, and the daily life of Riyad Mahrez like you've never seen before. <br></br><br></br>

                            The application, specially designed by Riyad Mahrez himself, aims to provide his fan community with the opportunity to interact with him through service requests, exclusive content, and other features that will be added over time. With this innovative way of connecting, you'll have the chance to join the fan community and enjoy exceptional rewards, from a signed jersey to an in-person invitation to a match event, and even the exclusive opportunity to interact with Riyad in person.<br></br><br></br>

                            Don't wait any longer; take the leap and join the new 2.0 community on RM26 app !</p>
                        <a href="/first_account_creation_step">Create your account now</a>
                    </div>
                </div>
                <div className="content-form">
                    <div className="container" >

                        <h2>Excited to discover <br></br>Riyad's World ?</h2>
                        <p>Create your account now</p>
                        <a href="/first_account_creation_step" className="anchor subscribe--propal">Create an account</a>

                    </div>
                </div>

                <div className="content-services">
                    <div className="container">
                        <h2>Services Riyad MahreZ App </h2>
                        <div className="services">
                            <div className="bloc-services">
                                <div className="items-services">
                                    <img src={videoCallPng} alt="Riyad Mahrez" />
                                    <p>Video call </p>
                                </div>
                                <div className="items-services">
                                    <img src={signedPng} alt="Riyad Mahrez" />
                                    <p>Signed jersey </p>
                                </div>
                            </div>

                            <div className="bloc-services-center">
                                <img src={iphoneRm26Png} alt="Riyad Mahrez" />
                            </div>

                            <div className="bloc-services">
                                <div className="items-services">
                                    <img src={vipPng} alt="Riyad Mahrez" />
                                    <p>VIP event </p>
                                </div>
                                <div className="items-services">
                                    <img src={birthdayPng} alt="Riyad Mahrez" />
                                    <p>Birthday wish </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-subscriptions">
                    <div className="container">
                        <h2>Subscriptions</h2>
                        <div className="subscriptions" id="subscribtion">
                            <SubscriptionItem offerType="free" toGo="1" />
                            <SubscriptionItem offerType="fan" toGo="1" />
                            <SubscriptionItem offerType="member" toGo="1" />
                        </div>
                    </div>

                    <div className="ultra-vip">
                        <div className="img"><img src={ultraVipSvg} alt="Riyad Mahrez" /></div>
                        <div className="desc">
                            <p>Ever dreamt of chatting with your favorite football player? Or spending a memorable evening with them? </p>
                        </div>
                        <div className="list">
                            <ul>
                                <li>Receipt of an "Ultra VIP" member card</li>
                                <li>Opportunity to meet Riyad Mahrez in person</li>
                                <li>Invitation to a Riyad Mahrez match in Saudi Arabia or Algeria</li>
                                <li>Exclusive conversations with Riyad Mahrez</li>
                                <li>Receipt of a signed football kit</li>
                            </ul>
                        </div>
                        <div className="price"><img src={priceVipSvg} alt="Riyad Mahrez" /></div>
                    </div>

                    <div className="container">
                        <h2>COMPARAISON</h2>
                        <div className="tableau">
                            <table className="tableau-list">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th><p className="gradient">Free</p></th>
                                        <th><p className="gradient">Fan</p></th>
                                        <th><p className="gradient">Member</p></th>
                                        <th className="vip"><p className="gradient">Ultra VIP</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><p className="gradient">Access to “free”</p></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                    </tr>
                                    <tr>
                                        <td><p className="gradient">Access to “fan content”</p></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                    </tr>
                                    <tr>
                                        <td><p className="gradient">Advertisements</p></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                    </tr>
                                    <tr>
                                        <td><p className="gradient">Access to RM26 services</p></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                    </tr>
                                    <tr>
                                        <td><p className="gradient">Participation in a recurring RM26 live event</p></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                    </tr>
                                    <tr>
                                        <td><p className="gradient">Receipt of a signed Al Ahli jersey by Riyad Mahrez</p></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                    </tr>
                                    <tr>
                                        <td><p className="gradient">Meeting with Riyad Mahrez</p></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                    </tr>
                                    <tr>
                                        <td><p className="gradient">Invitation to a Riyad Mahrez matchin Saudi Arabia / Algeria</p></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                    </tr>
                                    <tr>
                                        <td><p className="gradient">Private discussions / events withRiyad Mahrez</p></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><img src={okSvg} alt="Riyad Mahrez" /></td>
                                    </tr>
                                    <tr>
                                        <td><p className="gradient">Discount on RM26 services</p></td>
                                        <td><img src={nokSvg} alt="Riyad Mahrez" /></td>
                                        <td><p className="gradient">12%</p></td>
                                        <td><p className="gradient">25%</p></td>
                                        <td><p className="gradient">25%</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="content-form content-lunch">
                            <div className="container" id="newsletter">
                                <h2>Don’t miss the <br></br>launch !</h2>
                                <p>Subscribe to our newsletter to be the first to informed when it's live !</p>
                                <div class="_form_3"></div><NewsletterForm />



                            </div>
                        </div>



                    </div>

                </div>
            </main>

            <Footer />

        </div>
    );
}
