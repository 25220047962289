import Header from '../components/hearder';
import Footer from '../components/footer';



export default function PrivacyPolicy() {


    return (
        <div className='sprivacy_policy'>
            <Header />
            <main>
                <div className="container content-text">
                    <h1>Privacy Policy</h1>
                    <h2>HOW TO READ THIS DOCUMENT</h2>
                    <p>This notice applies to users of this application (the “App”). When using or creating an account to use this App, you become an authorized user (“user”, “you” or “your”) to use this App and our services. This privacy notice informs you about what we do with personal information that we will collect and process about you.</p>
                    <h2>1. OUR COMMITTMENT</h2>
                    <p>We take your privacy and confidentiality seriously. We implement best practices for protecting your data and ensuring that you can access and control them at any time. If the uses of your information change, we will provide you with more information when we are in contact with you. Where necessary, we would do that by updating our privacy notice so that you can get a notification the next time you connect to our App.</p>
                    <h2>2. WHO WE ARE</h2>
                    <p>KATAPULT SA (« Company », “we“, “us“ or “our“), is a company that operates under the laws of Switzerland (CHE-440.740.803) and having its registered offices located at EPFL Innovation Park D, 1015 Lausanne, Switzerland. This App is a web-based application accessible on the stores, which we use to provide you with our services with the available functionalities of the App. When handling personal information about you we will act as the data controller. If you have any questions related to handling of your personal information by us or to exercise your rights to privacy (section 12) you can contact us at info@katapultapp.com.</p>
                    <h2>3. WHAT INFORMATION WE COLLECT ABOUT YOU AND FOR WHAT PURPOSE</h2>
                    <p>various purposes, such as when you use the App, when subscribing to our newsletters, when creating an account, interacting with us or accessing our documentation. When doing so, we will only process information that relates to you for the purposes as set out in this privacy notice.
                        <br /><br />

                        Information that we collect automatically<br />
                        When you access our App, we may collect and use certain information about your device and your use of it. The information we collect may include your IP address, unique identifiers of your device, location data, information derived from cookies we may have stored on your device, which may contain personal data, and information about the pages visited, search terms entered or links clicked within the Platform. In the event we allow third party providers to place advertising on the App, you will have access to third party notices or, where applicable either give your prior permission or have the right to object.
                        <br /><br />

                        Information we obtain indirectly from you / via third parties<br />
                        In general, the App does not collect any personal about you indirectly, i.e. from third parties. However, in certain limited circumstances, we may request information from third parties in the context of the contractual or pre-contractual relationship with us.

                        <br /><br />
                        What categories of personal information do we collect?<br />
                        When connecting to our Platform and using the Application via your user account, we will collect personal information about you, including:<br />
                        -    Contact data, including when you create an account such as your full name, date of birth, address and location, phone number, e-mail address, or when contacting us for support, or when submitting information to us via a contact form;<br />
                        -    Financial data, such as your bank name and account to reimburse you of any amount that you may have paid to us, including, where applicable and strictly required for your identification your ID card or number;<br />
                        -    Aggregate information and statistics data, such as where applicable, through the use of tracking technologies. When placing optional cookies or similar tracking technologies that are non-essential on your device or to operate the website, we will do so on the basis of your prior permission, unless applicable data protection laws permit us to do so otherwise;<br />
                        -    Media content, such as images or videos, which may contain images, audio recordings and video of you. You remain responsible for the provision of such content to us and shall, unless legally permitted, ensure to either inform or collect prior consent from any other individual captured in such media content prior to sharing this content with us to respect their image rights;<br />
                        -    Instant messaging data, such as any information that you may share with us by instant messaging functionalities or, where available on the App;<br />
                        -    Profiling information, such as behavioural information about your use of the App or other devices<br /><br />


                        When do we collect personal information about you?<br />
                        a)    When entering into a pre-contractual or contractual relationship with us outside the App<br />
                        When we interact with you to outside the App, we may collect information from you, which may include information when you:<br />
                        •    create an account separately or become one of our customers to enter into a contractual relationship with us;<br />
                        •    subscribe to our newsletters.<br />
                        In the cases mentioned above, the information we collect and use is normally apparent from the context in which you provide it to us.<br />
                        b)    Upon creation of your user account within the App<br />
                        When you successfully submitted information about you create an account within the App, you will either login directly or receive login details separately in order to log into your user account, upload information to the secured portal, interact with us and use other features available on our App.<br />
                        Please note: Ensure that no confidential, nor sensitive personal data is shared nor transmitted via our App, (including via instant messaging), unless you are authorized to do so and, where required, you have duly informed data subjects about the processing of their personal data.<br />
                    </p>
                    <h2>4. WHY AND HOW DO WE USE INFORMATION ABOUT YOU</h2>
                    <p>In general, we use personal information about you only:<br />
                        •    for the necessity and the management of the contractual relationship with us or to provide you with our specific and agreed services via the App;<br />
                        •    according to our legitimate or private interest when you use the App or, where permitted, for statistics, administrative or billing purposes;<br />
                        •    where necessary, to comply with our legal obligations for transmission to competent authorities;<br />
                        •    where permitted or required by law upon your prior consent, such as subscribing to our newsletters, or sharing your personal data outside your country of residence in countries that do not provide the same level of protection to your privacy as in your country of residence using forms, creating your user account, etc.<br />
                        When processing your personal data, we collect and use information about you in accordance with all applicable data protection laws.<br />
                        For authorized users of the Application<br />
                        We process your personal information on the basis of the pre-contractual or contractual relationship that we will enter into with you and our legitimate interest. Such processing activities are listed below. In some cases, we do so for other reasons which are indicated to you in this section or separately via the App. None of your personal data is disclosed to third parties without guaranteeing their confidentiality and security or, where required by applicable law, with your prior consent.<br />
                        Table for data retention, legal basis and purpose for each category of personal data<br />
                        Categories of personal data    Purpose and legal basis for the processing    Retention period<br />
                        Account data<br />
                        •    contact and login data<br />
                        •    your contact details<br /><br />

                        Financial Data<br />
                        which includes data using Stripe, Apple Pay or Google Pay services in accordance with their terms of use and privacy notice.    Contractual relationship with us, management of the App and accounts    Duration of the contractual relationship or your use of the App<br />
                        The same data may be processed on the basis of our legitimate interest when sending you reminders or in the event of non-payment of invoices.
                        <br />Contact data<br />
                        Including your professional e-mail address, postal address and, where applicable, telephone number, etc.    In order to contact you for further information or in the event of a problem with the App, in the context of your contract with us.    <br />
                        E-mail address for marketing    We may either use your information on the basis of: (a) our legitimate interest in the context of the contract, or (b) your prior consent.     We will review and if required refresh consent from time to time. You will no longer receive newsletters after you unsubscribe. However, we keep your e-mail as long as you use the Application.<br />
                        Instant messaging data<br />
                        Any information that relates to the messages exchanged with us.  <br />
                        We process such data based on:<br />
                        •    the contractual relationship in order to respond to your queries; and<br />
                        •    legitimate interest and contractual relationship where the service is provided as a feature    We will retain instant messaging data as long as your account remains in effect.<br /><br />
                        Aggregated data for statistics, analytics and profiling    We use our legitimate interest to improve our service and for essential cookies that are not persistent. Where we do profiling or when we do not use your information, we may not need your consent, except to the use of certain tracking technologies as required.    No longer than permitted under applicable data protection laws.
                    </p>
                    <h2>5. WITH WHOM DO WE SHARE INFORMATION ABOUT YOU</h2>
                    <p>Who can access your personal data?<br /><br />
                        Only limited individuals can access your personal data, which may include the following:<br />
                        •    our duly authorised employees, consultants or workers;<br />
                        •    approved third parties, such as IT service providers, or auditors.<br />
                        Cross-border personal data transfer<br />
                        When we receive information about you or exchanging information with us or other users, we will receive and process your personal information in the country in which we are located. Also, our servers are located in Switzerland, as explained in sections 8 and 10, where the Swiss Data Protection Act applies, but which may slightly differ from your jurisdiction. However, Switzerland has a comprehensive data protection framework which ensures a high standard for the protection of personal data and to your privacy. When you exchange information with us, you acknowledge that personal information about you may leave your country of residence.<br /><br />
                        a)    Transfer within the EEA, Switzerland and approved countries
                        Switzerland is recognized by the EU Commission as a country with an adequate level of protection equivalent to the European privacy laws, which includes Regulation (EU) 2016/679 (GDPR). If you are located in a country of the European Economic Area (EEA) or in a country that is recognized as having an adequate legislation for the protection of personal data in your country (e.g.: Argentina, Australia, Canada, Israel, New Zealand, Uruguay), your rights to privacy are likely to rely on appropriate guarantees with regard to cross-border personal data transfer to Switzerland.<br /><br />
                        b)    Transfer to third country jurisdictions
                        If you are located in a country that does not recognize our country as having an adequate level of protection, we will rely on appropriate safeguards (such as the standard contractual clauses or other lawful transfer mechanisms) with you to access personal data outside your country of residence. Most of the time, you will share such personal information with us, via the App, on a voluntary basis, for the purpose of entering into a contract with FISU or on the basis of your consent. This occurs for example where the processing activity relates to: (a) the registration and management of your account, or (b) entering into a contract with us.<br /><br />
                        c)    Disclosure with third parties<br /><br />
                        In certain limited cases, authorized third parties outside our organization may access your data. These may include:<br />
                        •    third parties who provide us with services for the administration of the App (such as IT services in the event of a breakdown or for the maintenance of our App);<br />
                        •    financial services, such as Stripe, Apple Pay and Google Pay that you may use within the App to purchase certain services that we offer you (click on them to read their privacy notice) and which may access information strictly limited necessary for ensuring and securing the payment that you make via the App. You may access terms and notice from such financial providers here:<br /><br />
                        -    Stripe | Terms of use | Privacy notice<br />
                        -    Apple pay |  Guide for users  | Privacy notice<br />
                        -    Google pay |  Terms of use | Privacy notice<br /><br />
                        •    other authorized third parties, such as auditors, data center providers, or other authorised third parties only when required by law or by a court decision, to defend legal claims or in case of an investigation by a supervisory authority.
                        When engaging third parties, we have entered into agreements with them for the processing of your personal data so that such processing is carried out in accordance with our instructions, in a confidential, secure, transparent manner, to protect your privacy rights (section 12 to this notice) and comply with applicable data protection laws.
                        Please note: You can access the complete list of approved countries on the website of the Swiss Federal Data Protection and Information Commissioner where personal data transfers may be safe. Depending on your jurisdiction, you may still need to conduct an analysis on additional safeguards that may be required to transfer personal data to the country in which we operate.
                    </p>
                    <h2>6. WHAT COOKIES AND TRACKING TECHNOLOGIES WE USE</h2>
                    <p>Cookies or similar tracking technologies may be used on the App to automatically collect certain information for statistical purposes only.
                        <br /><br />What are cookies and tracking technologies?<br /><br />
                        Cookies are small text files that are placed on your device when you visit a site, which are then used to identify your device for the purposes described below. Cookies set by the owner of a site are called “first party cookies”. Cookies set by other people are called “third party cookies”. Third party cookies enable the third party to provide features or functionality on or through the site (like analytics, advertising and videos). The parties that set these third-party cookies can recognize your device both when it visits our site, and when it visits certain other sites.<br /><br />
                        Your web browser can be set to manage cookies and even to reject them. Do bear in mind that if you set your browser to automatically reject cookies, your user experience when visiting websites will not be the same: your preferences may not be remembered, some functionality may be lost and you may not be able to access certain areas or features of the sites.
                        <br /><br />In the event we use cookies or similar tracking technologies, we may use it on our websites and other digital services, including geo-localization, on our App. A cookie is a small data file that is placed on your device, which we use for the following purposes:
                        <br />•    Making your experience more efficient, faster and easier: by remembering your preferences, like preferred language, display and other settings, maintaining your session, and for authentication purposes. This helps us to provide you with a better user experience. These cookies are also referred to as session-Id cookies, authentication cookies, and user Interface customization cookies.
                        <br />•    Gain useful knowledge about how the site is used: by collecting information about the number of visitors and other uses. This helps us improve our sites. These cookies are also referred to as analytics cookies. For this purpose, we may use analytics services such as Google analytics, which means that Google and similar suppliers will also have access to this information (including your IP address and any other equipment identifiers such as the IMEI number and the MAC address).
                        <br />•    Provide easy access to our social media sites. This helps us to direct you and share with you our content within sites such as Facebook, Twitter, LinkedIn, Google Plus, YouTube or Pinterest. If we use any ‘social media plugins’, they may store cookies and similar technologies on your computer or other device. This means that the social media sites may access this information (including your IP address), may identify that you interacted with our App.
                        <br /><br />However, should we use one of such technology, and if you do not want certain data about you to be passed on to Google Analytics, please follow the instructions here to opt-out and deactivate the service. You may at any time set your browsing tools and refuse cookies that are not necessary to provide you with the service via the consent management tool that we may use from time to time on the App. For more information about cookies, you can visit the following website: https://www.allaboutcookies.org/.
                    </p>
                    <h2>7. HOW TO UNSUBSCRIBE FROM NEWSLETTERS</h2>
                    <p>We may send you information about our services, the contract with us or your membership, your account and information about our activities as a contractual necessity and based on our legitimate interest. When you agree to receive news about our activities through our newsletters, which is not linked to your subscription nor any contract with us, you always have the opportunity to easily unsubscribe, at any time, from our marketing communications. You can achieve this by using the "unsubscribe" link in our communications or by contacting us at info@katapultapp.com.</p>
                    <h2>8. HOW DO WE PROTECT INFORMATION ABOUT YOU</h2>
                    <p>Information security<br />
                        We have implemented all appropriate technical and organizational measures to protect your personal information in our possession from unauthorized disclosure, use, modification or destruction. In addition, we are committed to protecting to the extent possible all the personal information we process from you from unauthorized access, modification or disclosure and to ensuring confidentiality, integrity and availability of your data. We have entered into contracts with providers to build a secure cloud infrastructure that guarantees industry standards data security measures, including confidentiality.
                        <br /><br />List of security measures<br />
                        We have configured our systems to apply industry standard information security measures and used recognized security framework to protect your information, which includes, inter alia:
                        <br />•    TIER IV servers (ISO 27001 and FINMA) where data is hosted exclusively in Swiss data centers with dedicated hardware and on-site security;
                        <br />•    All systems are monitored by approved Swiss third-party IT service providers;
                        <br />•    Last generation firewalling;
                        <br />•    HTTPS and SSL encryption, disc encryption, Password strength requirements;
                        <br />•   Access controls via privileges and roles;
                        <br />•    Periodic penetration tests carried out.
                        <br /><br />Where we use third party suppliers to help us with information security measures, they have committed to comply with strict data protection requirements to ensure maximum confidentiality, integrity, and availability of your personal data.
                    </p>
                    <h2>9. OTHER INFORMATION ABOUT YOUR PRIVACY</h2>
                    <p>A)    Automated decision making and/or profiling<br />
                        We do not conduct any such tasks with user data via our App.<br /><br />
                        B)    Links to other websites<br />
                        Our service may contain links to other sites that are not operated by us. If you click on a third-party links, you will be directed to that third party’s site. We strongly advise you to review the privacy notice of every site you visit. We have no control over and assume no responsibility for the content, privacy notice or practices of any third-party sites or services.
                        <br /><br />C)    Children’s Privacy<br />
                        Our Service does not address anyone under the age 18, especially if the services require one-off or regular payments. However, we may allow you to use our service if you are under the age of 18, down to 16, or where applicable local data protection law permits us to do so, down to the age of 13 (“Children“). The age of 13 should be the minimum age under which you may not be allowed to use the App at all. We strongly recommend that you only use this App only if you are above the age of 18 (“Adult”), as we may have to request additional information about you if you are not yet an Adult. For Children using our services, you must get the prior permission of your parents or legal tutor to use our App and contract with us. In particular, we do not knowingly collect personally identifiable information from anyone under the age of 18 on a voluntary basis. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us if you disagree with this. If we become aware that we have collected personal information from Children without verification of parental, judicial or guardian’s consent, we take steps to remove that information from our servers.
                        <br /><br />D)    Data breach and incidents<br />
                        We have procedures and safeguards in place to identify, assess, investigate and report data breaches at the earliest possible time. Our procedures are robust and have been disseminated to our staff who are regularly trained and informed about good IT security practices. We also ensure the confidentiality, integrity and accessibility of your data at all times.
                    </p>
                    <h2>10. WHERE DO WE STORE YOUR INFORMATION</h2>
                    <p>Your data remains in Switzerland<br />
                        Although the Platform is accessible from anywhere in the world, we operate from our country where we are have our usual place of business. However, we will host any information that is used within the App about you in our servers located in Switzerland, which we use for hosting, backup and disaster recovery purposes. We do not transmit any information about you outside our offices and Switzerland via the App, except where necessary to use our authorized third parties.
                        <br /><br />Hosting location<br />
                        The hosting of your data is located on the servers of TiZoo Sàrl, a company located in, and operating from, Switzerland, with redundancy servers also located in another geographic area in Switzerland. You can contact Katapult SA, if you have any questions about the hosting of your personal data. In general, our hosting providers have configured their servers so that they cannot access your data without our express authorisation.
                        When you decide to download content that is stored in our servers via the App, you acknowledge that you will copy such information on your mobile device and that it will leave our Swiss servers. In such event, your data may no longer be protected.
                    </p>
                    <h2>11. HOW LONG DO WE KEEP YOUR INFORMATION</h2>
                    <p>Data retention<br />
                        We will keep personal information we collect about you for as long as necessary for providing the services via the App, and to comply with any legal obligations (e.g.: to comply with applicable legal, tax or accounting requirements and for archiving purposes).
                        Where we have no legitimate business interest to continue to process your personal data or if you ask us for deletion, we will either delete and ask our hosting providers and third parties to further delete your personal information, anonymise it or, if this is not possible (for example, if personal data has been stored in secured archives), we will securely store and isolate your information from any further processing until the deletion becomes possible and delete it as soon as technically possible. We will use any technology or other means to protect your data and mitigate any risks, such as obfuscation, blanking or encryption.
                        For more details on our data retention policies, please read section 4 to this privacy notice.
                        <br /><br />Retention schedule<br />
                        For each categories of processing activities and personal data, we apply a strict data retention policy that complies with privacy-by-design and privacy-by-default principles. If you have questions or need further information concerning our data retention schedule and practices, contact us at info@katapultapp.com.
                    </p>
                    <h2>12. YOUR RIGHTS TO PRIVACY</h2>
                    <p>As a user of our services and a customer on our App, and depending on your country of residence, you may have the right to exercise your rights or file a complaint before a competent data protection authority.
                        Access, Revision, Deletion<br /><br />
                        Under applicable privacy law, you may have a right to request a copy of information about you held by us. You may also have the right to revise, correct, or delete such information. Your rights to such information may be subject to limited legal and regulatory restrictions.
                        Objection to processing and additional rights<br /><br />
                        Under applicable privacy law (e.g. European data privacy law), you may formally object to the processing of your personal information. In certain circumstances under applicable law, you may have the additional right to restrict aspects of the processing of your information or ask for a copy of your data to be provided to you, or a third party, in a digital format.
                        California-Specific Rights<br /><br />
                        Under the California Consumer Privacy Act 2018 (CCPA) and its upcoming amendments such as the California Privacy Rights Act (CPRA), California residents have specific rights regarding their personal information held by private companies. In particular, we do not: (i) sell any personal information from individuals located in California, (ii) share any such personal information with third parties for their own commercial benefits, nor (iii) discriminate you in any kind. Californian individuals can exercise their rights by contacting us at info@katapultapp.com.<br /><br />
                        Rights of European Individuals to complain in front of Data Protection Authorities<br /><br />
                        In the event that any individual located in the EEA countries and Switzerland believes that we have processed information in a manner that is unlawful or breaches your rights, or has infringed the “General Data Protection Regulation”, or the Swiss Federal Data Protection Act, you have the right to complain directly to the applicable data protection authority. The list of those authorities can be found on the European Data Protection Board website or here: https://edpb.europa.eu/about-edpb/board/members_en.
                        <br /><br />
                        12.1 Account cancellation<br /><br />
                        If at any time you wish to delete your account on our platform, please contact us by sending an email to the following address: info@katapultapp.com. We will process your account deletion request promptly, in accordance with our privacy policy and applicable data protection laws. Please note that deleting your account will result in the loss of all data associated with your profile, and this action cannot be undone.</p>
                    <h2>13. CONTACT</h2>
                    <p>We provide easily accessible information via our website or on request. If you have any questions or requests related to data protection, please contact us at the following contact details:
                        Katapult SA<br /><br />

                        EPFL Innovation Park D<br /><br />

                        1015 Lausanne<br /><br />

                        Switzerland<br />
                        Email: info@katpaultapp.com
                    </p>

                </div>


            </main>
            <Footer />

        </div>
    );
}

