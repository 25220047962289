
import React, { useEffect, useState, useRef } from 'react';
//import { usePaymentInputs } from 'react-payment-inputs';

import { loadStripe } from '@stripe/stripe-js';
import { useElements, useStripe, Elements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';
const countryList = require('country-list');
const countryNames = countryList.getNames();
const stripePromise = loadStripe("pk_live_51Nt4unD6SKDGayctmAzyLyG9Ut4tMunyB8iDnPKkYE7pApzGcm7vx11Lp8nz9GEjZCzK8MNhHihJvcTI5sl8zcgR00E9YAsJaf");


export default function PaymentForm() {
    //const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();



    const [formData, setFormData] = useState({});

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        setFormData({ ...formData, country: selectedCountry });
    };


    useEffect(() => {
        // Récupérer les données depuis localStorage au chargement du composant
        const storedFormData = localStorage.getItem('formData');
        if (storedFormData) {
            setFormData(JSON.parse(storedFormData));
        }
    }, []);
    function CheckoutForm() {

        // collect data from the user
        const [name, setName] = useState("");
        const [email, setEmail] = useState("");
        const [priceId, setPriceId] = useState("");
        const [loading, setLoading] = useState(false);

        // stripe items
        const stripe = useStripe();
        const elements = useElements();

        const cardNumberElement = useRef(null);
        const cardExpiryElement = useRef(null);
        const cardCvcElement = useRef(null);
        const navigate = useNavigate();

        // main function
        const createSubscription = async (e) => {
            e.preventDefault();
            setLoading(true);

            try {


                // create a payment method
                const paymentMethod = await stripe?.createPaymentMethod({
                    type: "card",
                    /*card: {
                        number: elements.getElement(CardNumberElement),

                        exp_month: elements.getElement(CardExpiryElement),
                        exp_year: elements.getElement(CardExpiryElement).split('/')[0],
                        cvc: `20${elements.getElement(CardCvcElement).split('/')[1]}`,

                    },*/
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: formData.firstName,
                        email: formData.email,
                    },
                });
                console.log(paymentMethod);

                // call the backend to create subscription
                const response = await fetch("https://api.preprod.rm26.app/api/fan/create-subscription", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        paymentMethod: paymentMethod?.paymentMethod?.id,
                        name: formData.firstName,
                        email: formData.email,
                        priceId: formData.priceId,
                        password: formData.pass,
                    }),
                }).then((res) => res.json());
                console.log("name", name);
                console.log("email", email);
                console.log("price", priceId);
                console.log("reponse", response);
                /*const confirmPayment = await stripe?.confirmCardPayment(
                    response.clientSecret
                );*/
                // Après avoir obtenu response.clientSecret du backend
                const { clientSecret } = response; // Assure-toi que clientSecret est correctement extrait

                const confirmPayment = await stripe?.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: name, // Assure-toi que name est correctement défini
                            email: email, // Assure-toi que email est correctement défini
                        },
                    },
                });

                if (confirmPayment?.error) {
                    alert(confirmPayment.error.message);
                } else {
                    alert("Success! Check your email for the invoice.");
                    navigate('/success');
                }


                if (confirmPayment?.error) {
                    alert(confirmPayment.error.message);
                } else {
                    alert("Success! Check your email for the invoice.");
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                alert("Error : Please check if your credit card information is valid");
                setLoading(false);
            }

        };



        return (
            <div className="checkout--form">


                <div className="form-text card_number--input">
                    <label>Card number </label>
                    <div className="input payment_form--input">
                        <CardNumberElement
                            options={{}}
                            onReady={(element) => (cardNumberElement.current = element)}
                        />
                    </div> </div>
                <div className='exp_and_cvc'>
                    <div className="form-text">
                        <label>Expiration</label>
                        <div className="input payment_form--input">
                            <CardExpiryElement
                                options={{}}
                                onReady={(element) => (cardExpiryElement.current = element)}
                            /></div></div>
                    <div className="form-text">
                        <label>CVC</label>
                        <div className="input payment_form--input">
                            <CardCvcElement
                                options={{}}
                                onReady={(element) => (cardCvcElement.current = element)}
                            /></div></div>
                </div>
                <div className="form-text">
                    <label>Country</label>
                    <div className="input payment_form--input country--select">
                        <select >
                            <option disabled>Select</option>
                            {countryNames.map((countryName, index) => (
                                <option key={index} value={countryName}>
                                    {countryName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="form-button">
                  <div className={`submit ${loading ? 'submit--flex' : ''}`}>
                      {loading && <span class="loader"></span>}
                      <input type="submit" onClick={createSubscription} name="" value="Registration" disabled={loading} />
                  </div>
                </div>

            </div>
        );
    }

    return (
        <div className="content-form content-lunch payment--form">
            <div className="container" id="newsletter">


                {/*console.log("Données reçues dans PaymentForm :", formData)*/};
                {/*console.log("Email", formData.email)*/}
                {console.log(formData)}

                <h2>PAYMENT</h2>
                <p className='payment_form--amount'>{formData.propal}</p>


                <form name="" >


                    <Elements stripe={stripePromise}>
                        <CheckoutForm />
                    </Elements>


                </form>

            </div>

        </div>




    )
};
