import Header from '../components/hearder';
import Footer from '../components/footer';



export default function TermsOfService() {


    return (
        <div className='terms_of_service'>
            <Header />
            <main>
                <div className="container content-text">
                    <h1>Terms of Use</h1>
                    <h2>1. About us</h2>
                    <p>RM26 is a web-based mobile application (“App” or “Application”) operated by Katapult SA (“NAME OF THE CLIENT”, “we“, “us“ or “our“), an organization that operates under the laws of Switzerland (CHE-440.740.803) and having its registered offices located at EPFL Innovation Park D, 1015 Lausanne, Switzerland.<br /><br />

                        Via the Application, we will offer you with a digital tool that enables you to connect and purchase services related to Riyad Mahrez.<br /><br />

                        Please also refer to our privacy notice to understand how we process information about you when you will use the App.  If you do not want us to do so or if you do not agree with the Terms of Use of this App, you should not use it. If you have questions about these Terms of Use or about data privacy, please contact us at: info@katapultapp.com.
                    </p>
                    <h2>2. Services</h2>
                    <p>You can access or download our App free of charge. However, when you access our Application and its features, you may have to pay for the services that we provide to you via or outside the App upon subscription fees or other payment means and billing mechanisms that we may agree with you via the App or separately. <br /><br />

                        Account creation before entering into a contract with us<br /><br />

                        To use our Application, you must create an account.<br /><br />

                        To sign up to an account, you must provide certain information to us. You are required to enter this information truthfully and update it whenever outdated. The registration process is complete after you have received a confirmation email.<br /><br />

                        After your registration<br /><br />

                        After the creation of your user account, you become an authorized user with whom we may interact or conclude a contract for the provisions of our services. As an authorized user, you undertake to comply with the licence terms and rights and duties described under these Terms of Use.<br /><br />

                        Licence terms<br /><br />

                        We hereby grant you a free of charge, personal, time-limited, revocable, non-exclusive, non-sub-licensable and non-transferable license to use the app in accordance with these Terms of Use and for the permitted use only as authorized user.<br /><br />

                        Subscription Terms<br /><br />

                        To fully enjoy our service, please carefully review the subscription terms for the Premium and Member options below.<br /><br />

                        -	Premium subscription<br />
                        o	Cost: 3 USD per month<br />
                        o	Payment method: through your iTunes or Google account<br />
                        o	Automatic renewal: your subscription will automatically renew at the end of each month.<br />
                        o	Cancellation: to cancel your subscription, please do so at least 24 hours before the renewal date.<br />
                        o	Unsubscription: you will be able to unsubscribe from the subscription before the next renewal.<br /><br />

                        -	Member subscription<br />
                        o	Cost: 239.99 USD per year<br />
                        o	Payment method: through your iTunes or Google account<br />
                        o	Automatic renewal: your subscription will automatically renew at the end of each year.<br />
                        o	Cancellation: to cancel your subscription, please do so at least 24 hours before the renewal date.<br />
                        o	Unsubscription: you will be able to unsubscribe from the subscription before the next renewal.<br /><br />

                        You have full control over your subscriptions. At any time, you can access your phone settings to manage your subscriptions. Please ensure to update your preferences according to your needs.<br /><br />

                        By subscribing to any of our plans, you agree to the terms and conditions. <br /><br />

                        Permitted us of the App<br /><br />

                        You can only send us information that is accurate, appropriate and lawful. This applies in the event of conflict resolution or litigation with an opposing party. You are responsible for keeping your username and password confidential.  This means that you keep your password secret and do not allow third-party access.  If you suspect misuse of your account, please inform us immediately by sending an email to: info@katapultapp.com.<br /><br />

                        In addition, you undertake to: (i) use all necessary caution to limit as far as possible any transmission of information that is considered as sensitive or risky without lawful ground; (ii) abide to the terms of any contract that you may conclude with us after the creation of your account; (iii) not use, upload, reproduce, copy or further include any third party information, material, image, content, including any intellectual property rights, unless you are allowed or licensed to use or with the prior written consent of third parties.<br /><br />

                        To access more information about the processing of personal information about you and third parties you can access our privacy notice.<br /><br />

                        Restrictions to the use of the Application<br /><br />

                        You undertake not to create profiles on behalf of third parties, false profiles, nor to put online links to third party sites or any content that would be illicit, inappropriate or contrary to morality. You may not use our services for commercial purposes (sale, resale, rental, transfer, etc. of our services to third parties) without our prior written consent.<br /><br />

                        In addition, by accessing and using our Application, you shall not:<br /><br />

                        use the Application unlawfully, or breach any of these Terms of Use;<br /><br />

                        use the Application for your own or a third party commercial benefit or purposes;<br /><br />

                        use the Application if we or you believe that you infringe the current licence terms;<br /><br />

                        use exceed the restrictions to limited user account, such as using an account by appropriating the identity of a third party or opening and using an account on behalf of a third party without their prior permission;<br /><br />

                        not communicate information via the App, nor propose services that are contrary to public order, and common decency or illegal (such as in particular defending crimes against humanity, incitement to racial hatred, child pornography, to commit or attempt to commit fraud via the App, etc.);<br /><br />

                        disclose content that constitutes a breach of third party ownership rights, including in particular intellectual property rights;<br /><br />

                        except with our prior written consent, sell, copy, reproduce, hire, lend, distribute, transfer or grant a sub-licence over all or part of content appearing on the Application or decompiling, extracting, disassembling, modifying, posting in a legible format for the User, or attempting to discover any source code or using any software that activates or includes all or part of the Application.<br /><br />

                        attempt to obtain unauthorised access to the IT system for the Application or engaging in any activity liable to disrupt, reduce the quality of or interfere with the performance of or impair the proper operation of the Application;<br /><br />

                        not misuse the Application by voluntarily introducing viruses or any other form of malware and attempting to access the Application;<br /><br />

                        not infringe any of our intellectual property rights;<br /><br />

                        In the event we consider, for any reason, that you or any authorized User of the App is not complying with these Terms of Use, or third party terms of use, we may, at any time, and at our absolute discretion, close your user account, block your access to it and take any other action against you, including any form of civil or criminal court action. Our right to terminate your right to use the App under this licence is in addition to any damages that we may be entitled to claim against you as a result of the breach.
                    </p>
                    <h2>3. Third party services and access to the Application</h2>
                    <p>By using our services, you agree to the use of third-party services under the terms of these Apps. In particular, the Application operates via hosting providers exclusively located in Switzerland with industry standard best security practice.<br /><br />

                        Where possible we will use our own developed tools or third party, whether on-premise or cloud based. We ensure that we use such services as far as possible only if they guarantee your privacy and the confidentiality of your data (personal and confidential).<br /><br />

                        Third party services<br /><br />

                        When using the App, we may use subcontractors to outsource certain services to us or use technologies that are imbedded into the App. Such technologies are governed by terms and conditions of third parties and which are beyond our control. When using our App you also agree to the terms and conditions of those third parties, which should be displayed to you when you use those services. Such services may involve in particular the following third parties:<br /><br />

                        Financial services<br /><br />

                        Stripe | <a href="https://stripe.com/fr-ch/legal/consumer" target="_blank">Terms of use</a> | <a href="https://stripe.com/fr-ch/privacy" target="_blank">Privacy notice</a><br /><br />

                        Apple pay | <a href="https://www.apple.com/legal/applepayments/privacy-notice/)" target="_blank">Guide for users</a>  | <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" target="_blank">Privacy notice</a><br /><br />

                        Google pay | <a href="https://policies.google.com/terms" target="_blank">Terms of use</a> | <a href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en" target="_blank">Privacy notice</a><br /><br />

                        Please note when using the above financial services, certain rates may apply and vary from the platform that you use, which may impact the cost of the services. For example, although Stripe may apply and deduct amount of approximate 3%, Apple and Google Pay may apply until up to 30% on the amount that you pay on the services we provide to you. Such amount may vary from time to time and impact the final price of our products and services.<br /><br />

                        Hosting and IT services<br /><br />

                        Katapult SA – Application manager<br /><br />

                        PULSE.digital – Hosting Provider<br /><br />

                        If you want to know more about them you can contact us. You will get more information about how we respect your privacy on our privacy notice.
                    </p>
                    <h2>4. Functionalities and features of the Application</h2>
                    <p>When using the Application and accessing your user account, you may use some features and functionalities, which we may update, modify and make available to you from time to time. Please note that, although certain functionalities are free of charge, others are restricted and you may only access those groups upon payment. The functionalities may vary from time to time, but generally include the following categories of features:

                        <br /><br />Access our marketplace<br /><br />

                        This enables you to:<br /><br />

                        join private or public groups that we create<br /><br />

                        join events that we create<br /><br />

                        Group chat & content sharing<br /><br />

                        When we create groups you can chat with us and other individuals that may access our services and public or private groups. Within such groups, this allows to:<br /><br />

                        share<br /><br />

                        instant messages<br /><br />

                        materials that we may provide or make available to you via the App<br /><br />

                        Live audio or video streaming communication<br /><br />

                        Use the camera of your device in order to communicate via instant audio or video streaming or to share pre-recorder content<br /><br />

                        Manage your user account<br /><br />

                        Edit your personal data and manage your privacy within your profile, such as:<br /><br />

                        manage your personal data<br /><br />

                        configuration of the functionalities<br /><br />

                        decide which payment means you will use<br /><br />

                        Contact us<br /><br />

                        contact us via online forms<br /><br />

                        receive notifications from us
                    </p>
                    <h2>5. Disclaimer of Warranties</h2>
                    <p>The App is provided “as is”.  You use our App at your own risk.  We do not give any guarantee that the contents conform to the expectations of the user or that they shall reach a specific target they aim to.<br /><br />

                        We cannot guarantee the uninterrupted availability of our App, because we rely on telecommunications networks, which are beyond our control. We strive to minimize operational interruptions, e.g., due to maintenance of hardware or software.
                    </p>
                    <h2>6. Limitation of Liability</h2>
                    <p>We shall only be liable for resulting damages in so far as they result from a breach of a contractual duty through wilful or grossly negligent behaviour by us, or its legal representatives or agents. In case we breaches any essential contractual obligations due to slight negligence, our liability shall be limited to the foreseeable contractual damages. An essential contractual obligation is an obligation the fulfilment of which is essential for the proper execution of the contract and the compliance with which the contractual partner regularly relies on and may rely on.

                        <br /><br />Any further liability for damages is excluded. The liability for culpable injury to life, body or health in accordance with statutory provisions shall remain unaffected.
                    </p>
                    <h2>7. Copyright and Rights of Use</h2>
                    <p>The content provided by us is protected by the laws governing databases, copyright and trademarks. Such content may be neither copied nor disseminated nor used or copied in any manner whatsoever, without the prior agreement of the corresponding proprietors. This applies in particular to any copies made with the aid of robots, crawlers or other automatic mechanisms. Any use or modification of the services for purposes for which they were not intended is prohibited. In particular, copying or uploading of content, offers, directories, databases, etc. for commercial purposes is prohibited and will be subject to criminal and civil legal proceedings within the scope of existing legal options.</p>
                    <h2>8. Exclusion of liability</h2>
                    <p>We exclude our liability for breaches of contract due to negligence. This also applies to breaches of contract by your auxiliary persons and substitutes.<br /><br />

                        We are not liable for errors for which it is not responsible, in particular those that occur in the case of telecommunications operators, servers or other third-party providers such as social networks and online content distribution Apps. For App maintenance reasons, the site may occasionally and temporarily be unavailable. Any site maintenance operation will be carried out, as far as possible, outside office hours and preferably during the weekend. As the Site is accessible from all over the world, maintenance will be carried out outside office hours in Switzerland. You are bound by the terms of use of the Site and are responsible for the content you submit to us. Under no circumstances can we be held liable for any breach or violation of intellectual property rights or for damage caused to third parties.<br /><br />

                        You will compensate us for any amount claimed by a third party due to behaviour attributable to you, whether direct or indirect damage. As our referencing on search engines (Google, Yahoo, Bing, etc.) and social networks is carried out automatically, we cannot guarantee or exercise any referencing control on these search engines and therefore does not incur any responsibility for positioning and visibility. We are in no way responsible for the content of sites or external sources that refer to us.
                    </p>
                    <h2>9. Indemnification</h2>
                    <p>To the extent permitted under applicable law, you agree to indemnify and hold us harmless, our directors, officers, employees and agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your, your directors’, officers’, employees’ and agents’ violation of these Terms of Use.</p>
                    <h2>10. Notification for illicit or inappropriate content</h2>
                    <p>If you believe that any content infringes your rights or the rights of third parties, please inform us immediately so that we can examine your request and, if necessary, take appropriate measures to put an end to any infringement.

                        <br /><br />You can report any infringement to your rights, or the rights of third parties, by sending us an e-mail at the following address: info@katapultapp.com as soon as possible.

                        <br /><br />By these Terms of Use, you waive any claim against us for any damage caused to you by a third party, whether or not a member of our App. We reserve the right to immediately and without prior notice remove any content that may infringe the rights of third parties, including the blocking of accounts, and to inform the rightful owner, if necessary the competent authorities, when such an obligation is incumbent upon us.
                    </p>
                    <h2>11. Confidential Information</h2>
                    <p>With respect to any and all information of a confidential nature which appears or which you provide on our App (whether or not marked as such) including but not limited to any and all information relating to our compounds or targets, business and financial information, software, know-how, ideas and methods and techniques used by us in our business (“Confidential Information”), you agree to keep the same confidential and not to disclose the Confidential Information to any third party except with our prior express written consent.</p>
                    <h2>12. Data protection and privacy</h2>
                    <p>We respect your privacy. We undertake to use the information that the user provides only for the purpose of the services offered by the App and will allow access to the user's data only to our services or by third party services insofar as such access is necessary for the operation of the service, where permitted under these Terms of Use or with your prior permission. Where the services require the processing of personal data (personal information), please read our privacy notice for information about our processing activities and how we handle information that relates to you.

                        <br /><br />Under no circumstances do we sell or pass on your data to third parties in return for financial benefits. For more information, please refer to our privacy policy or contact us at this address if you have any questions: info@katapultapp.com.
                    </p>
                    <h2>13. Duration and termination</h2>
                    <p>These Terms of Use apply to the extent required for the Use of the App via your membership. These Terms of Use may terminate automatically, and we shall have the right to terminate for convenience and without notice, in the following events if you either:

                        <br /><br />terminate the contract with us; or

                        <br /><br />become in breach of those Terms of Use.

                        <br /><br />You may terminate these Terms of Use, to the extent they apply to your use of our Application, at any time by sending an email to info@katapultapp.com  asking us to delete your account.  We will send you an email as we deleted your account. Clauses 7 (Copyright and Rights of Use) and 11 (confidentiality) shall survive termination of these Terms of Use.

                        <br /><br />Once you have terminated these Terms of Use, you are no longer allowed to use our Application.

                        Please note that if your account is deleted, some limited information about you may still appear on the search engine of our App about you. For more information about how we manage personal information, please read our privacy notice.
                    </p>
                    <h2>14. Severability</h2>
                    <p>If any provision in these Terms of Use is or becomes wholly or partly ineffective, this shall not affect the effectiveness of the remaining provisions. In the event of a provision being ineffective, the parties shall agree upon an effective provision that, insofar as legally possible, most closely reflect what the parities to these Terms of Use intended.  The same shall apply in the event of any omission in the Terms of Use.</p>
                    <h2>15. Changes</h2>
                    <p>We reserve the right, at its sole discretion, to amend and adapt these Terms of Use with effect for the future, provided such change is reasonable.  We will indicate on our App that we revised the Terms of Use and, where possible, will inform you via email about those changes in time.  If you do not object to the applicability of the revised Terms of Use within 1 month after receipt, the amended Terms of Use shall be deemed accepted by you.  We will inform you about the 1 month period and your rights to object as well as the legal consequences of remaining silent in an appropriate form at the beginning of the notice period.</p>
                    <h2>16. Applicable Law and competent court for dispute resolution</h2>
                    <p>Except where imposed otherwise under any applicable law, these Terms of Use and all disputes arising in connection with these general conditions, including the validity thereof, or with the use of the App shall be governed by the laws of Switzerland, excluding the conflict of law-provisions of the United Nations Convention on Contracts for the International Sale of Goods.

                        <br /><br />The Parties agree that any dispute arising out from these Terms of Use shall be brought against the exclusive Court of Lausanne, Switzerland or, for dispute with consumers, either the location where we mainly operate or the competent court of your usual residence.
                    </p>
                    <h2>17. Contact</h2>
                    <p>If you have any questions or need support in connection with this App, please contact us at:<br /><br />

                        Mail address<br /><br />

                        Katapult SA<br /><br />

                        EPFL Innovation Park D<br /><br />

                        1015 Lausanne<br /><br />

                        Switzerland<br /><br />



                        Direct contact<br /><br />

                        E-mail: info@katapultapp.com<br /><br />
                    </p>
                </div>


            </main>
            <Footer />

        </div>
    );
}

