
import rmSvg from "../images/rm26.svg";
import instaSvg from "../images/insta.svg";
import linkedinSvg from "../images/linkedin.svg";
import facebookSvg from "../images/facebook.svg";
import twitterSvg from "../images/twitter.svg";

export default function Header() {
    return (
        <header>
            <div className="logo">
                <a href="/"><img src={rmSvg} alt="Riyad Mahrez" /></a>
            </div>
            <div className="social">
                <a href="https://www.instagram.com/riyadmahrez26.7/" target="_blank" rel="noreferrer" className="instagram"><img src={instaSvg} alt="Riyad Mahrez" /></a>
                <a href="https://www.linkedin.com/in/riyad-mahrez-18863b154/" target=" _blank" rel="noreferrer" className="linkedin"><img src={linkedinSvg} alt="Riyad Mahrez" /></a>
                <a href="https://www.facebook.com/Riyad.Mahrez.RM26.7" target="_blank" rel="noreferrer" className="facebook"><img src={facebookSvg} alt="Riyad Mahrez" /></a>
                <a href="https://twitter.com/Mahrez22" target="_blank" rel="noreferrer" className="twitter"><img src={twitterSvg} alt="Riyad Mahrez" /></a>
            </div>
        </header>
    )
};