
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


export default function SignUpForm() {

    const currentPageUrl = window.location.pathname;
    const endsWithOne = currentPageUrl.endsWith('1');
    const endsWithTwo = currentPageUrl.endsWith('2');
    const endsWithThree = currentPageUrl.endsWith('3');



    const navigate = useNavigate();
    const [formData, setFormData] = useState({});


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
        console.log(formData);
    };
    function passWordCheck(password) {
        const regexPassword = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])(?=.{8,})/;
        return regexPassword.test(password);
    }

    const handleFormSubmit = (e) => {

        e.preventDefault();

        if (passWordCheck(formData.pass)) {
          const updatedFanFormData = { ...formData, priceId: "price_1O7HcED6SKDGayctSH8tjkSs", propal: "USD 3.00/MONTH" };
          const updatedMemberFormData = { ...formData, priceId: "price_1OWbRtD6SKDGayct9vC5UU0T", propal: "USD 239.99 / YEAR" };
            if (endsWithTwo) {
                localStorage.setItem('formData', JSON.stringify(updatedFanFormData));
            } else if (endsWithThree) {
                localStorage.setItem('formData', JSON.stringify(updatedMemberFormData));
            }
            navigate('/third_account_creation_step');

        }
        else {
            alert("Password should be at least 8 characters in length and should include at least one upper case letter, one number, and one special character.")
        }







    };
    const handleFreeFormSubmit = async (e) => {
        e.preventDefault();

        const freeFormData = new FormData();
        freeFormData.append('prenom', formData.firstName);
        freeFormData.append('nom', formData.lastName);
        freeFormData.append('email', formData.email);
        freeFormData.append('password', formData.pass);
        console.log('Waiting ...', freeFormData)
        const response = await fetch("https://mahrez-backend.dvpt.pulsweb.ch/api/fan/register", {
            method: "POST",
            body: freeFormData,
        }).then((res) => res.json());

        console.log(response);
        if (response.status == true) {
            navigate('/success');
        }
        else {
            alert(response.response)
        }
    };



    return (


        <div className="content-form content-lunch sign_up--form">
            <div className="container" id="newsletter">
                <p>Welcome to the world of Riyad</p>
                <h2>SIGN UP</h2>
                {console.log(endsWithOne)}

                <form name="" onSubmit={endsWithOne ? handleFreeFormSubmit : handleFormSubmit}>
                    <div className="form-text">
                        <label>First name</label>
                        <div className="input people">
                            <input type="text" required name="firstName" onChange={handleInputChange} placeholder="First name" />
                        </div>
                    </div>
                    <div className="form-text">
                        <label>Last name</label>
                        <div className="input people">
                            <input type="text" required name="lastName" onChange={handleInputChange} placeholder="Last name" />
                        </div>
                    </div>
                    <div className="form-text">
                        <label>Email</label>
                        <div className="input mail">
                            <input type="email" required name="email" onChange={handleInputChange} placeholder="Email" />
                        </div>
                    </div>
                    <div className="form-text">
                        <label>Password</label>
                        <div className="input password">
                            <input type="password" required name="pass" onChange={handleInputChange} placeholder="password" />
                        </div>
                    </div>

                    <div className="genaral_conditions ">
                        <input type="checkbox" required />
                        <label>I have read and accept <a href="/privacy_policy" target="_blank"> the general conditions and the confidentiality notice</a></label>

                    </div>

                    <div className="form-button">
                        <div className="submit">

                            <input type="submit" name="" value="Registration" />

                        </div>
                    </div>


                </form>
                <div className='response'>

                </div>

            </div>
        </div>




    )
};
