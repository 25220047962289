
import firstStepPng from "../images/firsStep.png";
import secondStepPng from "../images/secondStep.png";
import thirdStepPng from "../images/thirdStep.png"

export default function AccountCreationTitle(props) {

    let img;
    if (props.step == "first") {
        img = <img src={firstStepPng} />
    } else if (props.step == "second") {
        img = <img src={secondStepPng} />
    } else {
        img = <img src={thirdStepPng} />
    }
    return (
        <div className='account_creation--title'>
            <h1>Create an account in 3 steps</h1>
            <p>select your preferred pack</p>
            {img}

        </div>
    )
};