import Header from '../components/hearder';
import video from "../images/video.png";
import successIcon from "../images/success.png";
import SuccessFooter from '../components/SuccessFooter';


export default function Success() {


    return (
        <div className='success'>
            <Header />
            <main>
                <div className='container content-top'>

                    <div className='left'>
                        <img src={successIcon} alt="Riyad Mahrez" className="successIcon" />
                        <p>Amazing</p>
                        <h1>Congratulations.<span>You are successfully registered</span></h1>
                    </div>
                    <div className='right'>
                        <img src={video} />
                    </div>

                </div >
            </main>
            <SuccessFooter />

        </div >

    );

}
