import Header from '../components/hearder';
import Footer from '../components/footer';



export default function ReturnAndRefundPolicy() {


    return (
        <div className='second-step--container'>
            <Header />
            <main>
                <div class="container content-text">
                    <h1>Return and Refund policy</h1>
                    <h2>Report misconduct  </h2>
                    <p>If something went wrong in the process of delivering a service, you can report it  and ask for a refund, or raise an issue to the app team. Try to sort out any issues directly with your influencer before triggering a report. Note that influencers have the same option of reporting a chat in case something went wrong. You (and the influencer) can report a service up to 7 days after it is closed.</p>
                    <h2>Password forgotten</h2>
                    <p>If you forgot your password and fail to login, open the app. Select "Log in" and then "Forgot Password".  You will get an email to restore your password. </p>
                    <h2>Support</h2>
                    <p>Contact: info@katapultapp.com</p>

                </div>


            </main >

            <Footer />

        </div >
    );
}

