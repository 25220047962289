import Header from '../components/hearder';
import Footer from '../components/footer';
import AccountCreationTitle from '../components/AccountCreationTitle';
import SignUpForm from '../components/SignUpForm';


export default function SecondAccountCreationStep() {


    return (
        <div className='second-step--container'>
            <Header />
            <div className='container'>
                <AccountCreationTitle step="second" />
                <div className='subscriptions'>

                    <SignUpForm />



                </div>
            </div>
            <Footer />

        </div>
    );
}

